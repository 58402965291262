import { useMemo, useCallback } from 'react';
import Wolves from './images/wolf_image.webp';
import Wolveswhite from './images/wolf_image2.webp';
import config from "./env.json";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useState } from "react";
import type { Container, RecursivePartial, IOptions } from "@tsparticles/engine";
import { Button } from '@material-tailwind/react';



const wolfShape = {
  type: 'image',
  // src: Wolves,
  width: 500,
  height: 500,
};

const App = () => {

  const [showModal, setShowModal] = useState(false);

  const Modal = ({ isOpen, onClose, children }: { isOpen: boolean; onClose: () => void; children: React.ReactNode }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-[#26282A] p-6 rounded-lg shadow-lg shadow-black max-w-lg w-full">
          {children}
          <button 
            onClick={onClose}
            className="mt-4 bg-blue-500 text-white shadow-lg shadow-black px-4 py-2 rounded hover:bg-blue-600 hover:scale-105 transition-transform duration-300 ease-in-out"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const [theme, setTheme] = useState('light');

const handleThemeChange = () => {
  setTheme(theme === 'light' ? 'dark' : 'light');
};


  const [ init, setInit ] = useState(false);

  const RoadMap1 = [
    { title: 'S1 2024', text: 'Mini app Launch \n\n InlineGPT \n\n Referral System ', compeleted: true },
    { title: 'S2 2024', text: 'Wolves 2.0 \n\n UGC Program \n\n Rewarding System \n\n Connect Wallet', compeleted: true },
    { title: 'S3 2024', text: 'web3 Game \n\n More AI Tools \n\n More Bonus System ', compeleted: false },
    { title: 'S4 2025', text: 'Partnership \n\n Avatar \n\n Whitepaper \n\n TGE On TON \n\n .\n.\n.', compeleted: false },
    
  ]
  

  const FAQ = [
    {question: 'What is the Wolves Miniapp?', answer: 'The <a href="https://t.me/wolvespack_bot/app" target="_blank">Wolves Miniapp</a> rewards users with $Wolves based on their Telegram account age. Utilizing AI tools like GPT, it enhances Telegram interactions for greater crypto rewards.'},
    {question: 'How do i earn rewards in Wolves Airdrop?', answer: 'Earn rewards in Wolves Airdrop by completing tasks, referring friends and utilizing <a href="https://t.me/wolvesgpt" target="_blank">InlineGPT</a>.'},
    {question: 'How does Wolves UGC Program Works?', answer: 'Create content featuring your wolfId on any social media platform. Our team will review it, and if it meets our guidelines, we will promote your ID in the Wolves <a href="https://t.me/wolvesugc" target="_blank">UGC program</a>.'},
    {question: 'How can i use Wolves GPT?', answer: 'First, subscribe to the <a href="https://t.me/realwolves_community" target="_blank">Wolves community</a>. Then, join the <a href="https://t.me/wolvesgpt" target="_blank">Wolves GPT</a> group and use /ask or mention <a href="https://t.me/wolvespack_bot" target="_blank">@wolvespack_bot</a> in any Telegram chat.'},
    {question: 'Is Wolves a crypto meme coin?', answer: 'Yes, Wolves Crypto is indeed a dynamic crypto meme coin driven by a strong community, blending fun and investment in the exciting world of digital currencies!'},
    {question: 'Are there any fake or scam apps pretending to be Wolves?', answer: 'Yes, there are several apps that mimic our mini app,Wolves. These imitation apps are not affiliated with us and may compromise your security or provide misleading functionalities.We advice users to be cautious and ensure they are interacting with the official <a href="https://t.me/wolvespack_bot" target="_blank">wolves bot</a> on Telegram.'},
  ]

  useEffect(() => {
      initParticlesEngine(async (engine) => {
          await loadSlim(engine);
      }).then(() => {
          setInit(true);
      });
  }, []);

  const particlesLoaded = (container: Container) => {
    console.log(container);
};
  
// 1. Memoize the particle options
const particleOptions: RecursivePartial<IOptions> = useMemo(() => ({
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      resize: {
        enable: true,
        delay: 0.5,
      },
    },
    modes: {
      push: { quantity: 2 },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    links: {
      color: theme === 'dark' ? '#F2E9EA' : '#000000',
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 3,
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: { default: 'bounce' },
      random: false,
      speed: 6,
      straight: false,
    },
    number: {
      density: { enable: true },
      value: 80, // Reduced from 100
    },
    opacity: { value: 0.5 },
    shape: wolfShape,
    size: { value: { min: 1, max: 5 } },
  },
  detectRetina: true,
}), [theme, wolfShape]);

// 2. Optimize the particle loading callback
const handleParticlesLoaded = useCallback(async (container?: Container) => {
  if (container) {
    particlesLoaded(container);
  }
}, []);

return (
  <>
    <body className={`flex flex-col min-h-screen w-full ${theme === 'dark' ? 'bg-[#26282A] text-[#F2E9EA]' : 'bg-gradient-to-b from-orange-300 to-orange-400 transition-opacity select-none duration-1000'} sm:text-base md:text-lg lg:text-xl`}>
     <header>
      <button 
         className={`shadow-lg shadow-black rounded-full text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold py-2 sm:py-3 md:py-4 px-2 sm:px-3 md:px-4 flex items-center justify-center transition duration-300 ease-in-out group w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 hover:w-auto hover:px-4 sm:hover:px-5 md:hover:px-6 absolute top-4 sm:top-6 left-4 sm:left-6 md:left-8 z-20 ${theme === 'dark' ? 'bg-[#C9AF94] text-[#26282A]' : 'bg-[#26282A] text-[#F2E9EA]'} active:w-auto active:px-4 sm:active:px-5 md:active:px-6`}
         onClick={handleThemeChange}>
        <span className={`opacity-0 max-w-0 group-hover:max-w-[200px] group-hover:opacity-100 transition-all ease-out overflow-hidden whitespace-nowrap ${theme === 'dark' ? 'text-[#26282A]' : 'text-[#F2E9EA]'}`}>{theme === 'light' ? 'Dark Mode' : 'Light Mode'}</span>
              {theme === 'light' ? (
                <svg className={`w-4 h-4 sm:w-5 sm:h-5 transition-transform duration-300 ease-in-out group-hover:translate-x-1 ml-1 sm:ml-2 stroke-white`} fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                </svg>
              ) : (
                <svg className={`w-4 h-4 sm:w-5 sm:h-5 transition-transform duration-300 ease-in-out group-hover:translate-x-1 ml-1 sm:ml-2 stroke-[#26282A]`} fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              )}
      </button>
     </header>

      <main className="flex-grow p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 flex flex-col items-center justify-center">
        <section className="flex flex-col sm:flex-row items-center z-20 hover:transform hover:scale-105 hover:rotate-x-10 hover:rotate-y-10 transition duration-500 ease-in-out">
          <img 
            src={theme === 'dark' ? Wolveswhite : Wolves} 
            alt="wolves pack" 
            className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 xl:w-64 xl:h-64 object-cover rounded-full transition duration-500 ease-in-out"
          />
          <h1 className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold transition duration-500 ease-in-out ${theme === 'dark' ? 'text-[#F2E9EA]' : 'text-black'}`}>WOLVES</h1>
        </section>
                  

       <section className="absolute top-6 right-4 sm:right-20 z-20">
          <button className={`shadow-lg shadow-black rounded-full text-sm sm:text-base md:text-xl font-bold py-2 sm:py-3 md:py-4 px-2 sm:px-3 md:px-4 flex items-center justify-center transition duration-300 ease-in-out group w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 hover:w-auto hover:px-4 sm:hover:px-5 md:hover:px-6 hover:scale-105 active:scale-95 ${theme === 'dark' ? 'bg-[#C9AF94] text-[#26282A]' : 'bg-[#26282A] text-[#F2E9EA]'}`}>
            <svg className={`w-4 h-4 sm:w-5 sm:h-5 transition-transform duration-300 ease-in-out group-hover:translate-x-1 mr-1 sm:mr-2 ${theme === 'dark' ? 'stroke-[#26282A]' : 'stroke-white'}`} fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>
          <span className={`opacity-0 max-w-0 group-hover:max-w-[200px] group-hover:opacity-100 transition-all ease-out overflow-hidden whitespace-nowrap whitespace-pre-line text-xs sm:text-sm md:text-base ${theme === 'dark' ? 'text-[#26282A]' : 'text-[#F2E9EA]'}`}>Whitepaper</span>
          </button>
        </section>
        
        <section className="flex flex-col items-center justify-center z-20">
          <Button
                className={`relative inline-flex items-center justify-center p-0.5 mb-4 sm:mb-6 md:mb-8 mt-8 sm:mt-12 md:mt-16 mx-2 overflow-hidden text-sm sm:text-base font-bold shadow-lg shadow-black text-[#F2E9EA] rounded-lg group hover:text-[#F2E9EA] transition-transform hover:scale-105 active:scale-95 ${theme === 'dark' ? 'bg-[#C9AF94]' : 'bg-[#26282A]'}`}
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              >
              <a href="https://t.me/wolvespack_bot/app" className={`relative px-3 sm:px-4 md:px-5 py-2 sm:py-3 md:py-4 transition-all ease-in duration-75 rounded-md ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`}>
                Play Wolves
              </a>
          </Button>

            <Button
                  className={`relative inline-flex items-center justify-center p-0.5 mb-4 sm:mb-6 md:mb-8 mt-4 sm:mt-6 md:mt-8 mx-2 overflow-hidden text-sm sm:text-base font-bold shadow-lg shadow-black text-[#F2E9EA] rounded-lg group hover:text-[#F2E9EA] transition-transform hover:scale-105 active:scale-95 ${theme === 'dark' ? 'bg-[#C9AF94]' : 'bg-[#26282A]'}`}
                  placeholder=""
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  onClick={() => setShowModal(true)}
                >
                <span className={`relative px-3 sm:px-4 md:px-5 py-2 sm:py-3 md:py-4 transition-all ease-in duration-75 rounded-md ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`}>
                  Terms of Services
                </span>
            </Button>
        </section>
        
        <div className={`relative text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold mb-4 pt-8 hover:transform hover:scale-105 hover:rotate-x-10 hover:rotate-y-10 transition duration-300 ease-in-out ${theme === 'dark' ? 'text-[#F2E9EA]' : ''}`}>Roadmap</div>
         <div className="flex flex-col justify-center items-center space-y-8 my-6 z-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-6 ">
            {RoadMap1.map((item, index) => (
              <div key={index} className={`w-full max-w-sm md:max-w-md lg:max-w-lg lg:w-[300px] lg:h-[280px] h-auto xl:w-[300px] xl:h-[280px] 2xl:w-[400px] 2xl:h-[300px] shadow-lg shadow-black rounded-xl lg:rounded-full ${theme === 'dark' ? 'bg-[#C9AF94]' : 'bg-gradient-to-br from-gray-800 to-gray-900'} flex flex-col items-center p-6 transform transition-all duration-300 hover:scale-105 hover:rotate-3 md:hover:rotate-6 lg:hover:rotate-6 ${item.compeleted ? `border-4 ${theme === 'dark' ? 'border-[#FF6D6A]' : 'border-[#4B68B8]'}` : ''}`}>
                <h3 className={`text-2xl sm:text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`}>{item.title}</h3>
                <div className="flex-grow flex items-center justify-center">
                  <p className={`text-base sm:text-lg text-center whitespace-pre-line mb-4 ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`}>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className={`relative py-14 text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold mb-4 pb-8 hover:transform hover:scale-105 hover:rotate-x-10 hover:rotate-y-10 transition duration-300 ease-in-out ${theme === 'dark' ? 'text-[#F2E9EA]' : ''}`}>FAQ</div>
        <div className="flex flex-col justify-center items-center space-y-8 my-6 z-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {FAQ.map((item, index) => (
              <div key={index} className={`w-full max-w-sm h-auto shadow-lg shadow-black rounded-2xl ${theme === 'dark' ? 'bg-[#C9AF94]' : 'bg-gradient-to-br from-gray-800 to-gray-900'} flex flex-col items-center p-6 transform transition-all duration-300 hover:scale-105 hover:rotate-3`}>
                <h3 className={`text-2xl sm:text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`}>{item.question}</h3>
                <div className="flex-grow flex items-center justify-center">
                  <p className={`text-base sm:text-lg text-left whitespace-pre-line mb-4 ${theme === 'dark' ? 'text-black' : 'text-[#F2E9EA]'}`} 
                     dangerouslySetInnerHTML={{ __html: item.answer }}>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>        
        <div className="mt-8 flex flex-col items-center">
        <div className={`relative text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-bold mb-4 pb-8 hover:transform hover:scale-105 hover:rotate-x-10 hover:rotate-y-10 transition duration-300 ease-in-out ${theme === 'dark' ? 'text-[#F2E9EA]' : ''}`}>Follow Us</div>
          <div className="flex justify-center items-center gap-4 sm:gap-6 md:gap-8 flex-wrap -mt-10">
          {[
            { url: theme === 'dark' ? 'https://img.icons8.com/?size=100&id=84909&format=png&color=000000' : 'https://img.icons8.com/?size=100&id=84909&format=png&color=ffffff', link: config.REACT_APP_YOUTUBE_CHANNEL },
            { url: theme === 'dark' ? 'https://img.icons8.com/?size=100&id=de4vjQ6J061l&format=png&color=000000' : 'https://img.icons8.com/?size=100&id=de4vjQ6J061l&format=png&color=ffffff', link: config.REACT_APP_X_CHANNEL },
            { url: theme === 'dark' ? 'https://img.icons8.com/?size=100&id=F4ZPUh2Mk5tk&format=png&color=000000' : 'https://img.icons8.com/?size=100&id=F4ZPUh2Mk5tk&format=png&color=ffffff', link: config.REACT_APP_TELEGRAM_CHANNEL },
            { url: theme === 'dark' ? 'https://img.icons8.com/?size=100&id=84884&format=png&color=000000' : 'https://img.icons8.com/?size=100&id=84884&format=png&color=ffffff', link: config.REACT_APP_INSTAGRAM_CHANNEL }
          ].map((item, index) => (
          <a key={index} href={item.link} target="_blank" className="no-underline">
            <div className={`w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 rounded-full ${theme === 'dark' ? 'bg-[#F2E9EA]' : 'bg-[#26282A]'} flex justify-center items-center overflow-hidden cursor-pointer shadow-lg transition-transform duration-300 ease-in-out hover:scale-110`}>
              <img src={item.url} alt={`Logo ${index + 1}`} className="w-4/5 h-4/5 object-contain" />
            </div>
          </a>
          ))}
        </div>
       </div> 
      </main>
    </body>


    <Particles
      particlesLoaded={handleParticlesLoaded}
      options={particleOptions}
    />

  <footer className={`text-sm text-center py-1 w-full sm:text-base md:text-lg lg:text-xl xl:text-2xl ${theme === 'dark' ? 'text-black bg-[#C9AF94]' : 'text-[#F2E9EA] bg-[#26282A]'}`}>
    Copyright 2024 Team WolvesPack
  </footer>

  <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
    <div className="max-h-[80vh] overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4 text-white">Terms of Services for Wolves Bot</h2>
      <p className='text-white whitespace-pre-line mb-2'>
        <h6 className="font-bold">1. Introduction</h6> 

        Welcome to Wolves Bot. By accessing our services, you agree to comply with these Terms of Services. If you do not agree, please refrain from using our platform.
      </p>
      <p className='text-white whitespace-pre-line mb-2'>
      
        <h6 className="font-bold">2. Service Description</h6>

        Wolves Bot rewards users with $Wolves coin based on their Telegram account age. The rewards system is intended for legitimate users, and any misuse will result in penalties.
      </p>
      <p className='text-white whitespace-pre-line mb-2'>

      <h6 className="font-bold">3. User Responsibilities</h6>

        Users agree to provide accurate information and to use the bot responsibly. Any fraudulent or deceptive activity, including the creation of multiple accounts, will lead to account suspension or termination.
      </p>
      <p className='text-white whitespace-pre-line mb-2'>

      <h6 className="font-bold">4. Intellectual Property</h6>

        All content, design, and functionality within the Wolves Bot are protected by copyright, trademark, and other laws. Unauthorized copying, imitation, or modification of our app or services is strictly prohibited.
        </p>
        <p className='text-white whitespace-pre-line mb-2'>

        <h6 className="font-bold">5. Legal Action Against Imitators</h6>

        Any individual or company that mimics, copies, or replicates our bot or services will be subject to legal action. We reserve the right to pursue lawful punishment against such actions to the fullest extent permissible by law.
        </p>
        <p className='text-white whitespace-pre-line mb-2'>

        <h6 className="font-bold">6. Limitation of Liability</h6>

        Wolves Bot is not liable for any indirect, incidental, or consequential damages arising from your use or inability to use our services. We make no guarantees regarding the availability or functionality of the bot.
        </p>
        <p className='text-white whitespace-pre-line mb-2'>

        <h6 className="font-bold">7. Modifications to Terms</h6>

        We may update these Terms and Services from time to time. Changes will be effective immediately upon posting. Continued use of our services following any changes constitutes acceptance of the new terms.
        </p>

        <p className='text-white whitespace-pre-line mb-2'>
        <h6 className="font-bold">8. Contact Information</h6>

        For any questions regarding these Terms and Services, please contact us via our email (support@wolvespack.pro).
        </p>
        <p className='text-white whitespace-pre-line mb-2'>

        <h6 className="font-bold">9. Acceptance</h6>

        By using Wolves Bot, you acknowledge that you have read, understood, and agreed to abide by these Terms of Services.
      </p>
    </div>
  </Modal>
  </>
  );
}

export default App;